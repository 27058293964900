function addListeners(elements, handler) {
  Array.from(elements).forEach(element => {
    element.addEventListener('click', handler);
  });
}

// function addListener(element, handler) {
//   element.addEventListener('click', handler);
// }

function eyeIconHandler() {
  this.style.display = 'none';
  this.nextElementSibling.style.display = '';
  this.previousElementSibling.type = 'text';
}

function slashedEyeIconHandler() {
  this.style.display = 'none';
  this.previousElementSibling.style.display = '';
  this.previousElementSibling.previousElementSibling.type = 'password';
}

document.addEventListener('turbo:load', () => {
  addListeners(document.getElementsByClassName('password-hide-button'), eyeIconHandler);
  addListeners(document.getElementsByClassName('password-show-button'), slashedEyeIconHandler);
});
